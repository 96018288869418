<template>
  <main class="thanks">
    <div class="thanks__wrapper">
      <img src="static/svg/smile.svg" />
      <div class="thanks__content">
        <span class="thanks__title">Спасибо, оплата прошла успешно</span>
        <span v-if="order_number" class="thanks__subtitle">Номер вашей покупки: {{ order_number }}</span>
      </div>
      <router-link :to="{ name: 'home' }" class="btn btn--main">Продолжить покупки</router-link>
    </div>
  </main>
</template>

<script>
export default {
  name: "ThanksPage",
  data() {
    return {
      order_number: null,
    };
  },
  mounted() {
    let params = this.parseParams(new URLSearchParams(window.location.search));
    this.order_number = params.id;
  },
  methods: {
    parseParams(params) {
      let parsedParams = {};
      params.forEach((value, key) => {
        try {
          parsedParams[key] = JSON.parse(value);
        } catch (e) {
          parsedParams[key] = value;
        }
      });
      return parsedParams;
    },
  },
};
</script>

<style lang="stylus">
.thanks {
  padding 45px 100px
  display flex
  justify-content center
  align-items center

  & a{
    text-decoration: none;
  }

  & .btn{
    padding 0 10px
  }

  &__wrapper{
    display flex
    flex-direction column
    gap 50px
    align-items center
  }

  &__content{
    display flex
    align-items center
    flex-direction column
    gap 20px
  }

  &__title{
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
  }

  &__subtitle{
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }
}
</style>
